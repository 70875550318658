@keyframes infoText-slidein
{
    0%{
        transform:scale(0);
      }
    100%{
      transform:scale(1);
      
        }
}

.infoText-slideIn{
    animation:infoText-slidein  1s ease-out forwards;
  }

  @keyframes serviceText-slideUp
{
    0%{
        transform: translateY(1000px);
        opacity:0;
      }
    100%{
      transform: translateY(0px);
      opacity:1;
      
        }
}

.serviceText-slideUp{
    animation:serviceText-slideUp 1s ease-out forwards;
  }

  .infoText-slideIn{
    animation:infoText-slidein  1s ease-out forwards;
  }

  
  @keyframes serviceText2-fadeIn
{
    0%{
        opacity:0;
      }
    100%{
      opacity:1;
      
        }
}

.serviceText-fadeIn{
    animation:serviceText2-fadeIn 0.5s ease-in forwards;
  }

  @keyframes serviceText-fadeOut
  {
	  0%{
		  opacity:1;
		}
	  100%{
		opacity:0;
		
		  }
  }
  
  .serviceText-fadeOut{
	  animation:serviceText-fadeOut 1s ease-out forwards;
	}

  @keyframes serviceText2-fadeIn
{
    0%{
        opacity:0;
      }
    100%{
      opacity:1;
      
        }
}

.serviceText2-fadeIn{
    animation:serviceText2-fadeIn 0.5s ease-in forwards;
  }

  @keyframes serviceText2-fadeOut
  {
	  0%{
		  opacity:1;
		}
	  100%{
		opacity:0;
		
		  }
  }
  
  .serviceText2-fadeOut{
	  animation:serviceText2-fadeOut 0.5s ease-out forwards;
	}

  @keyframes servicesImage-slideInRight
  {
      0%{
          transform:translateX(1000px);
          opacity:0
        }
      100%{
        transform:translateX(0px);
        opacity:1;
        
          }
  }


body{
	padding:0px;
	margin:0px;
}

.decoration-div-blue{
    position:absolute;
    top:0px;
    left:150px;
    height:100%;
    width:150px;
    opacity:0.08;
    background-color:#003366;
    z-index: 2;
}

.decoration-div-black{
    position:absolute;
    top:60%;
    left:0px;
    height:150px;
    width:100%;
    opacity:0.08;
    background-color:black;
    z-index:2;
}

.serviceBorder{
	border-top:10px black solid;
	border-bottom:10px black solid;
}

.header-format{
	font-size:55px;
	color:white;
	font-weight:bolder;
}

.demo-message{
text-align:center;   
margin-top:100px;
}

.drop-color{
    color:white;
}

.drop-color:hover{
    color:#003366;
    background-color:white;
}

/*mark{
	opacity:0.9;
	background-color:gray;
}*/

#companyLogo{
	width:70px;
	height:50px;
}

#header{
	background:url('../images/Rectitude_Logos/HeaderImage1.jpg') no-repeat;
	background-size:cover;

	}

/*#background{
	background-image:url('../images/technologyImage.jpg');
}*/

#color{
	background-color:#003366;
	color:white;
	border-bottom: medium #000d1a solid;
	border-top: medium #000d1a solid;

}
#carousel{
	height:400px;
	max-height:100%;
	max-width:100%;
}
#menu{
	margin-left:350px;
}

#socialBlue{
	background-color:#000d1a;
}



#footer_id{
	background-color:#000d1a;
	color:white;
}


#webCreation{
	overflow:hidden;
text-align:center;
	padding:40px;
	background:url('../images/Rectitude_Logos/webCreationImage2_B.jpg') no-repeat;
	background-size: cover;
	height:100vh;
	margin-top:0px;
}

#tutoring{
text-align:center;
	padding:40px;
	background:url('../images/Rectitude_Logos/ben-white-148430.jpg');
	background-size: cover;
	height:100vh;
	margin-top:0px;
}

#webHosting{
text-align:center;
	padding:40px;
	background:url('../images/Rectitude_Logos/Pictures1/WebHosting.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height:100vh;
	padding-top:100px;
}

#formJumbo{
text-align:center;
	padding:40px;
	background-image: -moz-linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8)),url('../images/Rectitude_Logos/Contact_Us.jpg');
	background-image: -o-linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8)),url('../images/Rectitude_Logos/Contact_Us.jpg');
	background-image: -webkit-linear-gradient(rgba(255,255,255,0.8),rgba(255,255,255,0.8)),url('../images/Rectitude_Logos/Contact_Us.jpg');
	background-size: cover;
	margin-top:0px;
	padding-top:200px;
}



 #webHosting .jumbotron{
	
	
	max-width:500px;
	margin:auto;
	color:white;
 }
	

 #tutoring .jumbotron{
	
	padding: 40px;
	float:left;

	width:400px;
	margin-left:30px;
	margin-top:30px;
	color:white;

}


 #webCreation .jumbotron{

	padding-bottom:40px;
	
	max-width:970px;
	margin:auto;
	
	background-image: -moz-linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0.6));
	background-image: -o-linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0.6));
	background-image: -webkit-linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0.6));background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border:solid 1px #003366;
    width:fit-content;
    padding:2px;
    border-radius: 5px;
}

 #formJumbo .jumbotron{
	border-radius: 5px;
	padding: 40px;
	background-color:rgba(51,153,255,0.65);
	max-width:970px;
	margin:auto;
	margin-top:65px;
}



#services{
	/*border-radius:20px;*/
	text-align:center;
	margin-top:0px;
	margin-bottom:0px;
	/*max-width:970px;
	margin:auto;*/
}

#footer_blue{
	color:#00CCFF;
}

.buttonColor{
	background-color:black;
}


.icon-bar{
	background-color:white;
}


.info{
	text-align:center;
	margin-top:20px;
	margin-bottom:10px;
	width:600px;
	margin:auto;
}


.rec-logos{
	height:161px;
	 width:230px;
	 margin-left:100px;
	 }

.inconText{
	font-weight:bold;
	text-align:center;
}

.sliderText{
	color:black;
	font-size:25px;
}

.footer_class{
	/*height:250px;*/
	margin-top:65px;
}

.footer_justify{
padding-right:20px;
padding-left:20px;
}

.menuBar{
	max-width:970px;
	margin:auto;
}

.contactSize{
	font-size:30px;
	/*color:#003366*/;
}

.formStyle{
	text-align:center;
	color:#003366;
	font-weight:bolder;
}

.longer-column{
    margin-bottom:135px;
}

.longer-top{
    margin-top:30;
}

 .border-top{
	border-top:solid black 20px;
 }

.init-bg{
	width:100vw;
	height:100vh;
	background-size: cover;
	overflow: hidden;
	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.copyright-footer{
	text-align:center;
}

.footer-links{
	color:white;
	cursor:pointer;
}

.footer-links:hover{
	color:#00CCFF;
}

 @media screen and (max-width:300px){
  	.stop{
    	width:300px;
  	}
 }

 @media screen and (max-width:882px){
	#webCreation{
		/*background:none;*/
		background-image:-moz-linear-gradient(rgba(255,255,255,0.60),rgba(255,255,255,0.60)),url('../images/Rectitude_Logos/webCreationImage2_B.jpg');
		background-image: -o-linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0.6)),url('../images/Rectitude_Logos/webCreationImage2_B.jpg');
	    background-image: -webkit-linear-gradient(rgba(255,255,255,0.4),rgba(255,255,255,0.6)),url('../images/Rectitude_Logos/webCreationImage2_B.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height:auto;
	}
}

.aboutUs-format{
	height:100px;
}

@media screen and (max-width:500px){
	#tutoring .jumbotron{
		width:100%;
	}
	.info{
		width:100%;
	}
}

@media screen and (max-width:1001px){
	.aboutUs-format{
		height:200px;
	}
}

