/*@media screen and (max-width:970px){
	#webCreation .jumbotron{
	padding:20px;
	}
}*/

@keyframes infoText-slidein
{
    0%{
        transform:scale(0);
      }
    100%{
      transform:scale(1);
      
        }
}

.infoText-slideIn{
    animation:infoText-slidein  1s ease-out forwards;
  }

  @keyframes serviceText-slideUp
{
    0%{
        transform: translateY(1000px);
        opacity:0;
      }
    100%{
      transform: translateY(0px);
      opacity:1;
      
        }
}

.serviceText-slideUp{
    animation:serviceText-slideUp 1s ease-out forwards;
  }

  .infoText-slideIn{
    animation:infoText-slidein  1s ease-out forwards;
  }

  @keyframes serviceText-fadeIn
{
    0%{
        opacity:0;
      }
    100%{
      opacity:1;
      
        }
}

.serviceText-fadeIn{
    animation:serviceText-fadeIn 1s ease-out forwards;
  }

  @keyframes servicesImage-slideInRight
  {
      0%{
          transform:translateX(1000px);
          opacity:0
        }
      100%{
        transform:translateX(0px);
        opacity:1;
        
          }
  }

  .servicesImage-slideInRight{
    animation:servicesImage-slideInRight 1.5s ease-in forwards;
  }

  .servicesImage-slideInRight2{
    animation:servicesImage-slideInRight 1.5s ease-in forwards;
    animation-delay: 1s;
    -o-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
  }

  .servicesImage-slideInRight3{
    animation:servicesImage-slideInRight 1.5s ease-in forwards;
    animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
  }


    .info-container{width:100%;}


    .const serviceBackground{background-color:#ccffff;}

	.sampleWebPic{
		position:absolute;
		top:70px;
		left:445px;
		width:500px;
		height:330px;
	}

	.web-creation-div{
		width:300px;
		color:#003366;
		float:left;
	}

	.web-creation-text{
		font-size:25px;
	}

  .formatDiv{height:100px;}

	.laptop-div{
    position:relative;
    overflow:hidden;
	/*background:url("../images/Rectitude_Logos/laptop.png") no-repeat;*/
    /*background:url('../images/Rectitude_Logos/webCreationImage2_B.jpg') no-repeat;
    background-size: 100%;
    background-position: center;
    border:red solid 2px;*/
    width:500px;
    height:400px;
	}

.laptop-format-container{
  position:absolute;
  width:fit-content;
  height:fit-content;
  bottom:30px;
  left:45%;
}


  .laptop-image{
    width:100%;
    height:100%;
  }

  .demo-image{
   width:100%;
   height:100%;
  }

  .demo-container{
    position:absolute;
    top:35px;
    right:83px;
    width:350px;
    height:230px;
    z-index:15;
  }


	.grid-container{
		width:100vw;
		display:grid;
		grid-template-rows:1fr 1fr;
	}

	.web-creation-services{
		width:700px;
		margin:auto;
	}

	.web-service-header{
		color:#003366;
		font-weight:bolder;
		opacity:0;
	}

	.web-service-text{
		opacity:0;
		font-weight:bold;
	}

	.demo_image1{
		opacity:0;
    cursor:pointer;
	}

	.demo_image2{
		opacity:0;
    cursor:pointer;
	}

	.demo_image3{
		opacity:0;
    cursor:pointer;
	}

  .zIndex{
    z-index: 30;
  }
  
  .showLoveImage{
    width:fit-content;
  }

  @media screen and (max-width:1002px){
		.web-creation-div{float:none;width:100%;margin:auto;}
    .laptop-format-container{position:static;width:100%;}
    .laptop-div{margin:auto;}
    .formatDiv{height:200px;}
   }

	@media screen and (max-width:879px){
		.web-creation-div{float:none;width:100%;margin:auto;}
   }

	@media screen and (max-width:700px){
		.web-creation-services{width:100%;}
   }

   @media screen and (max-width:499px){
		.demo-image{width:100%;}
    .laptop-div{width:100%;}
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 5px);}
   }

   @media screen and (max-width:470px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 20px);}
   }

   @media screen and (max-width:442px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 30px);}
   }

   @media screen and (max-width:424px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 40px);}
   }

   @media screen and (max-width:410px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 60px);}
   }

   @media screen and (max-width:372px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 70px);}
   }

   @media screen and (max-width:358px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 80);}
   }

   @media screen and (max-width:354px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 87px);}
   }

   @media screen and (max-width:322px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 95px);}
   }

   @media screen and (max-width:310px){
    .demo-container{top:5%;right:14%;width:75%;height:calc(50% - 100px);}
   }