h1{
	text-align: center;
	color:#003366;
}

.spanStyle {
	float: right;
	margin-top: 7px;
}

.height{
	height:500px;
}

.backGroundPicture{
	background:url('../images/Rectitude_Logos/404_Background.jpg') no-repeat;
	background-size:cover;
}