.navbar-dropdown2{
    position:absolute;
    background-color:#003366;
    color:white;
    right:0;
    top:25px;
    width:200px;
    border: 1px solid white;
    box-shadow: 10px 10px 5px black;
    cursor:pointer;
    border-radius: 5px;
    transform:scale(0);
}

.highlight:hover{
background-color:white;
}


.dropdown-contents {
    position: fixed;
    background-color:white;
    color:#003366;
    width:100%;
    z-index: 21;
    text-align:center;
    top:200px;
    left:0;
  opacity:0;
  }

  .dropdown-contents-on {
    position: fixed;
    background-color:white;
    color:#003366;
    width:100%;
    z-index: 21;
    text-align:center;
    top:200px;
    left:0;
  opacity:1;
  }

  .show-dropdown-content {
    position: fixed;
    background-color:white;
    color:#003366;
    width:100%;
    z-index: 21;
    text-align:center;
    top:200px;
    left:0;
    display:static;
  }

  .dropdown-text-div{
      cursor:pointer;
      color:#003366;
      background-color:white;
  }



  .dropdown-text-div2:hover{
    cursor:pointer;
    color:white
}

  .dropdown-format{
      position:relative;
  }

  .dropdown-text-div:hover{
    background-color:#003366;
    color:white;
  }

  .dropdown-services{
      color:#003366;
  }

  .dropdown-services:hover{
      color:white;
      background-color:#003366;
  }


.fadein{
    transition: fadein ease-in 400ms forwards;
}

.fadeout{
    transition: fadeout ease-in 400ms forwards;
}

@keyframes fadein {
    from{
        opacity:0;
    }

    to{
        opacity:1;
    }
}

@keyframes fadeout {
    from{
        opacity:1;
    }

    to{
        opacity:0;
    }
}

