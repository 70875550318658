@keyframes serviceText-slideUp
{
    0%{
        transform: translateY(1000px);
        opacity:0;
      }
    100%{
      transform: translateY(0px);
      opacity:1;
      
        }
}

.serviceText-slideUp{
    animation:serviceText-slideUp 1s ease-out forwards;
  }

  @keyframes serviceText-fadeIn
{
    0%{
        opacity:0;
      }
    100%{
      opacity:1;
      
        }
}

.serviceText-fadeIn{
    animation:serviceText-fadeIn 2s ease-in forwards;
  }

  .serviceText-fadeIn2{
    animation:serviceText-fadeIn 2s ease-in forwards;
    animation-delay: 1.5s;
    -o-animation-delay:1.5s ;
    -moz-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
  }

  .h2-container{
    width:fit-content;
    opacity:0;
    color:#003366;
    font-weight: bold;
    font-size:large;
    text-align: center;
    margin-top:40px;
    margin-left:auto;
    margin-right:auto;
  }

  @keyframes servicesIcon-slideInRight
  {
      0%{
          transform:translateX(1000px);
          opacity:0
        }
      100%{
        transform:translateX(0px);
        opacity:1;
        
          }
  }

  .servicesIcon-slideInRight{
    animation:servicesIcon-slideInRight 1.5s ease-in forwards;
  }

  .servicesIcon-slideInRight2{
    animation:servicesIcon-slideInRight 1.5s ease-in forwards;
    animation-delay: 1s;
    -o-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -webkit-animation-delay: 1s;
  }

  .servicesIcon-slideInRight3{
    animation:servicesIcon-slideInRight 1.5s ease-in forwards;
    animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
  }

.image-format-div{
    width:fit-content;
    margin:auto;
    opacity:0;
}

.image-format-div2{
    width:200px;
    margin:auto;
    opacity:0;
}

.services-text{
    font-size:50px;
    color:#003366;
    font-weight:bolder;
    opacity:0;
}

.services-text-info{
    font-size:20px;
    font-weight:bold;
    opacity:0;
}

.services-div{
width:500px;
margin:auto;
text-align:center;
}

.video-container{
  display: flex;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
    width:100vw;

    /*background-image:url("../images/Rectitude_Logos/Rectitude-Solution_Logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size:500px;*/
    padding-top:10px;
    padding-bottom:10px;
}

.video-div{
    margin-left:40px;
    width:500px;
    height:300px;
    opacity:0;
}

.video-text{
    color:white;
    font-weight: bold;
    font-size:larger;
    text-align: center;
}

.video{
    width:100%;
    height:100%;
}

.container{
  overflow:hidden;
}

@media screen and (max-width:500px){
  .video-container{width:100%;}
    .video-div{width:100%;height:100%;}
    .services-div{width:100%;}
    .h2-container{display:none;}
  }

  @media screen and (min-width:500px){
      .h2-container2{display:none;}
    }