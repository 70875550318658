.form-container{
    background-color:#003366;
    width:500px;
    margin:auto;
    border-radius:5px;
    padding:10px;
    color:white;
}

.form-header{
    color:white;
    text-shadow: 2px 2px black;
}

@media screen and (max-width:500px){
    .form-container{width:100%;}
}