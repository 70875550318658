@keyframes dropdown-fadeIn
{
    0%{
        transform:scale(0);
      }
    100%{
      transform:scale(1);
      
        }
}


@keyframes dropdown-fadeIn1
{
    0%{
        transform:scale(0);
      }
    100%{
      transform:scale(1);
      
        }
}





@keyframes dropdown-fadeout
{
    0%{
      transform:scale(1);
      
        
      }
    100%{
      transform:scale(0);
      
        }
}

.navbar-dropdown-fadeIn{
  animation:dropdown-fadeIn 1s ease-out forwards;
}

.navbar-dropdown-fadeOut{
animation:dropdown-fadeout 1.5s ease-out;
}


.chevron-format{
  font-size:12px;
}

.divOn{
    transform:scale(1);
  }

.navbar{
    position:fixed;
    z-index:20;
    width:100vw;
    height:100px;
    padding-right:10px;
    padding-left:10px;
}

.bg{ 
    
  background-color:transparent;
  color:#003366;

}

a{
  background-color:transparent;
  color:#003366;
}

.navbar-links{
  width:fit-content;
}


.bg.scrolled{
  transition: 0.5s ease;
  background-color: #003366;
  color:white;
}


.under-line{
  width:fit-content;
  display:inline-block;
  
}

.under-line:hover{
  border-bottom: #003366 3px solid;
  color:#003366;
}

.under-line.scrolled:hover{
  border-bottom: white 3px solid;
  color:white;
}

.under-line.scrolled{
  color:white;
}


/*.bg.scrolled a{

  color:black;
}*/

/*background-color:#003366;
    color:white;*/

.navbar-dropdown{
    position:absolute;
    background-color:#003366;
    left:0;
    top:25px;
    width:200px;
    border: 1px solid white;
    box-shadow: 10px 10px 5px black;
    cursor:pointer;
    border-radius: 5px;
    transform:scale(0);
}



.dropdown-text{
  color:black
}

.dropdown-text:hover{
    background-color:white;
}
.dropdown-services{
    position:relative;
}

.logo-format{
    position:absolute;
    left:0px;
    top:10px;
    width:100px;
}

.navbar-menu-container{
  position: relative;
    width:700px;
    font-size:20px;
    margin:auto;
    /*border:2px solid red;*/
}

.navbar-text-format{
    margin-right:55px;
    text-transform: capitalize;
    cursor:pointer;
}

.navbar-text-format2{
  text-transform: capitalize;
  cursor:pointer;
}

/*.navbar-text-format:hover{
    background-color:white;
    color:blue;
}*/

.icon-div{
    padding-right:20px;
}

.elipses-container{
  position: absolute;
  font-size:40px;
  cursor:pointer;
  width:fit-content;
  left:10px;
  top:45%;
}

.elipses-container:hover{
  color:black;
  background-color: white;
}

@media screen and (max-width:1000px){
  .navbar-menu-container{margin:auto;}
  .navbar{height:200px;}
}

@media screen and (min-width:1000px){
  .format-nav-div{display:none;}
}

@media screen and (max-width:848px){
  .navbar-menu-container{position:absolute;left:20%;top:45%;}
  .icon-div{position:absolute;right:10px;top:10px;}

}

@media screen and (max-width:752px){
  .navbar-menu-container{position:absolute;left:10%;top:45%;}
}

@media screen and (max-width:664px){
  .navbar-menu-container{display:none;}
}

@media screen and (min-width:664px){
  .elipses-container{display:none;}
}

@media screen and (min-width:999px){
  .elipses-container{display:none;}
  .format-div-tutoring{display:none;}
}