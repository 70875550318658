@keyframes infoText-slidein
{
    0%{
        transform:scale(0);
      }
    100%{
      transform:scale(1);
      
        }
}

.infoText-slideIn{
    animation:dropdown-fadeIn 1s ease-out forwards;
  }
  
.anmimted-container{
    position:relative;
    background-image:-moz-linear-gradient(rgba(255,255,255,0.95), rgba(255, 255, 255, .95)),url("../images/Rectitude_Logos/Pictures1/igor-miske-207639.jpg");
   /* background-image: url('../images/Rectitude_Logos/Pictures1/igor-miske-207639.jpg');
    background-image:url('../images/Rectitude_Logos/Pictures1/igor-miske-207639.jpg');*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow:hidden;
    height:100vh;
    width:100vw;
}




.init-bg-v2{
    /*margin-left:auto;
    margin-right:auto;*/
    width:100%;
    height:100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
   
}

.animated-div-container{
   margin:auto;
    width:450px;
    height:200px;
    z-index:20;
}

.animated-text-container{
    text-align:center;
    margin-left:100px;
    margin-top:150px;
    width:fit-content;
}

.animated-text{
    font-size:50px;
    color:#003366;
    text-align: center;
}

.animated-text2{
    font-size:70px;
    color:#003366;
    width:fit-content;
}

.service-info-text{
    position:absolute;
    top:30%;
    right:150px;
    text-align:center;
    font-size:40px;
    color:#003366;
    font-weight:bolder;
    width:400px;
}

@media screen and (max-width:446px)
{
    .animated-div-container{
        width:100%;
    }
}

@media screen and (max-width:1100px)
{
    .animated-container{
        display:block;
    }
    .animated-text{
        width:100%;
        position:static;
        display:block;
        margin-left:0px;
        margin-right:0px;
    }

    .service-info-text{
        position:static;
        display:block;
        width:100%;
        margin-left:0px;
        margin-right:0px;
    }

    .init-bg-v2{
        width:100%;
        position:static;
        display:block;
        margin-left:0px;
        margin-right:0px;
    }

    .animated-text-container{
        width:100%;
        margin-left:0px;
        margin-right:0px;
    }

   
}