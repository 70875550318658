h1{
	font-size:20px;
}

.aws-text{
	font-weight:bolder;
	font-size:large;
}


.serviceBackground{
background-color: #ccffff;
}

.auto-style1 {
	margin-top: 1px;
}

@media screen and (max-width:970px){
	#webHosting .jumbotron{
	padding:20px;
	}
}

@media screen and (max-width:600px){
	#webHosting .jumbotron{
	padding:0px;
	}
}

@media screen and (max-width:755px){
	#webHosting{
	}
}

@media screen and (max-width:300px){
	.stop{
	width:300px;
	}
}