h1{
    text-align:center;
}

.formatPage{
  font-size:20px;
}
.formatFaq{
  background-image: url("../images/Rectitude_Logos/Questionmark.jpg");
  background-repeat: no-repeat;
  background-position: center;
     }