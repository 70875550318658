.serviceBackground{
    background-color: #ccffff;
    }

    @keyframes serviceText-fadeIn
{
    0%{
        opacity:0;
      }
    100%{
      opacity:1;
      
        }
}

.format-div-tutoring{
    height:80px;
}

.serviceText-fadeIn{
    animation:serviceText-fadeIn 3.5s ease-in forwards;
  }

  .tutoring-header{
      color:#003366;
      font-weight:bolder;
      opacity:0;
  }

  .tutoring-info-text{
    color:#003366;
      font-weight:bolder;
      opacity:0;
  }

    .tutoring-text{
        font-size:larger;
        font-weight:bolder;
        color:white;
    }
    
    .auto-style1 {
        margin-top: 1px;
    }
    
    @media screen and (max-width:970px){
        #tutoring .jumbotron{
        padding:20px;
        }
    }
    
    @media screen and (max-width:300px){
        .stop{
        width:300px;
        }
    }