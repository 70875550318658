.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
  
  .modal-main2 {
    position:fixed;
    /*background-image:url('../background-images/mic_modal.jpg');*/
    background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    text-align:center;
    width: 700px;
    border-radius: 10px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding-bottom:15px;
    color:black;
    border:#003366 solid 2px;
  }

  .modal-main {
    position:fixed;
    background-color:aliceblue;
    color:black;
    background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    text-align:center;
    width: 700px;
    border-radius: 10px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding-bottom:15px;
    border:#003366 solid 2px;
  }

  .modal-header{
    color:black;
    font-weight:bolder;
    text-shadow: 2px 0 0 #c85d4d;
    font-family: 'Dancing Script', cursive;
  }

  .modal-header-div{
    width:120px;
    margin:auto;
    cursor:pointer;
  }

  .modal-format{
    z-index:22;
    padding:15px;
    overflow-y: scroll;
    height:500px;
  }

  .modal-image{
    width:200px;
  }

  .background-div{
    z-index:21;
  }

  .close-button{
    width:80px;
    color: #003366;
    font-size:25px;
    float:right;
  }

  .close-icon{
    cursor: pointer;
  }

  .close-icon:hover{
    color:#BDC3C7;
  }

  .span-font{
    color: #c85d4d;
    font-weight:bold;
    font-size:15px;
  }

  .span-font:hover{
    color: #BDC3C7;
  }

  .label-text{
    display:inline;
  }

  .modal-text-div{
    width:100%;
  }

  .login-modal-links{
    cursor:pointer;
  }

  .login-modal-links:hover{
    color: #BDC3C7;
  }

  .registerSpan{
    text-align: left;
  }

  .passwordSpan{
    text-align: right;
    margin-left:200px;
  }

  .login-text-field{
    width:80%;
    margin:auto;
  }

  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }

  .signIn{
    color:white;
  }

  .signIn:hover{
    color:black;
  }

  .example{
    color:blue;
  }

  .help-title{
    font-weight:bolder;
    text-align: center;
    font-size:35px;
    width:fit-content;
  }

  
  @media screen and (max-width:700px){
   .modal-main{width:100%;transform:none;left:0%;top:10%}
   .passwordSpan{margin:0px;text-align: center;}
 }

 @media screen and (min-width:700px){
  .break-modal{display:none;}
  
}