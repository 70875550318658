h1{
    text-align:center;
}

h5{
  text-align:center;
}

     .formatPage{
  font-size:20px;
}
.formatBlog{
     background:url("../images/Rectitude_Logos/EducationAndTechnology.jpg") no-repeat;
     background-size:cover;
}

.blog-format{
  height:200px;
}

@media screen and (max-width:300px){
	.stop{
	width:300px;
	}
}
