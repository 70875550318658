.captcha-image{
    width:100%;
    height:150px;
}

.captcha-imageDiv{
    width:300px;
    height:150px;
    margin:auto;
}

.captcha-text{
    text-align: center;
}

.button-div{
    width:300px;
    height:80px;
    margin:auto;
    position:relative;
}

.captchaButton-format{
    display:inline-block;
    margin:auto;
}

.captchaButton-position1{
    top:10px;
    left:0;
    position:absolute;
}

.captcha-textboxDiv{
    width:60%;
    margin:auto;
}

.captchaButton-position2{
    top:10px;
    right:0;
    position:absolute;
    cursor:pointer;
}

.captchaButton-position2:hover{
    background-color:#003366;
    color:white;
    border:solid white 1px;
}

.captchaButton-position1:hover{
    background-color:#003366;
    color:white;
    border:white solid 1px;

}


.form-captcha{
    width:100%;
}

.red-border{
    border:red 2px solid;
}

.help-button{
    cursor:pointer;
    margin-left:20px;
    width:25px;
    height:25px; 
}

.submit-button{
    background-color:white;
    color:#003366;
    border-radius:1px;
}

/*.form-control{
    width:calc(100%-20px);
}*/

@media screen and (max-width:400px)
{
    .captchaButton-position1{
        position:static;
        margin:auto;
    }

    .captchaButton-position2{
        position:static;
        margin:auto;
    }
}

@media screen and (min-width:399px)
{
    .break{display:none;}
}

@media screen and (max-width:600px)
{
    .captcha-textboxDiv{width:80%;}
}

@media screen and (max-width:300px)
{
    /*.captcha-textboxDiv{width:calc(100%-20px);}*/
    .button-div{width:100%;margin:0px;}
}

@media screen and (max-width:398px)
{
    .captcha-imageDiv{width:100%;margin:0px;}
}

